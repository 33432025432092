import Mixin from "@/utils/mixin";
import { DcOrderApi } from "@/api";
import Icon from "@/components/Icon";
import PreviewMedia from "@/components/PreviewMedia";
export default {
  components: {
    Icon: Icon,
    PreviewMedia: PreviewMedia
  },
  mixins: [Mixin],
  data: function data() {
    return {
      isShowMedia: false,
      mediaSource: '',
      mediaType: 'audio',
      previewVisible: false,
      previewImage: '',
      info: {},
      orderForm: "KNOWHERE App",
      dataSource: [],
      loading: false,
      columns: [{
        title: '时间',
        dataIndex: 'logtime',
        key: 'logtime',
        scopedSlots: {
          customRender: 'logtime'
        },
        width: '33%'
      }, {
        title: '用户',
        dataIndex: 'operator',
        key: 'operator',
        scopedSlots: {
          customRender: 'operator'
        },
        width: '33%'
      }, {
        title: '详情',
        dataIndex: 'operation',
        key: 'operation',
        scopedSlots: {
          customRender: 'operation'
        },
        width: '34%'
      }],
      priceColumns: [{
        title: '费用项',
        dataIndex: 'name',
        key: 'name',
        scopedSlots: {
          customRender: 'name'
        },
        width: '16.6%',
        align: 'center'
      }, {
        title: '商品金额',
        dataIndex: 'price',
        key: 'price',
        scopedSlots: {
          customRender: 'price'
        },
        width: '16.6%',
        align: 'center'
      }, {
        title: 'Gas fee',
        dataIndex: 'Gasfee',
        key: 'Gasfee',
        scopedSlots: {
          customRender: 'Gasfee'
        },
        width: '16.6%',
        align: 'center'
      }, {
        title: '平台服务费',
        dataIndex: 'servicefee',
        key: 'servicefee',
        scopedSlots: {
          customRender: 'servicefee'
        },
        width: '16.6%',
        align: 'center'
      }, {
        title: 'Creator Fee',
        dataIndex: 'creatorfee',
        key: 'creatorfee',
        scopedSlots: {
          customRender: 'creatorfee'
        },
        width: '16.6%',
        align: 'center'
      }, {
        title: '总计',
        dataIndex: 'total',
        key: 'total',
        scopedSlots: {
          customRender: 'total'
        },
        width: '16.6%',
        align: 'center'
      }],
      priceData: [{
        legalitemprice: 0,
        itemprice: 0,
        legalgasfee: 0,
        gasfee: 0,
        legalservicefee: 0,
        servicefee: 0,
        legalcreatorfee: 0,
        creatorfee: 0,
        totalprice: 0,
        legaltotalprice: 0
      }]
    };
  },
  computed: {
    orderno: function orderno() {
      return this.$route.query.orderno;
    }
  },
  created: function created() {
    this.load(); // 详情
  },

  methods: {
    copySuccess: function copySuccess(e) {
      this.toast("复制成功", "success");
    },
    copyError: function copyError(e) {
      this.toast('复制失败', 'error');
    },
    preview: function preview() {
      var previewImage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
      var previewType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      if (!previewType || previewType == 1) {
        this.previewImage = previewImage;
        this.previewVisible = true;
      } else {
        var mediaType = previewType == 2 ? 'audio' : 'video';
        this.mediaType = mediaType;
        this.mediaSource = previewImage;
        this.isShowMedia = true;
      }
      // console.log(previewImage,previewType,this.mediaType,this.mediaSource,this.isShowMedia)
    },
    previewHandleCancel: function previewHandleCancel() {
      this.previewVisible = false;
      this.isShowMedia = false;
    },
    load: function load() {
      var _this = this;
      DcOrderApi.detail({
        orderno: this.orderno
      }).then(function (res) {
        // console.log(res)
        _this.info = res;
        _this.priceData = [{
          legalitemprice: res.legalitemprice || 0,
          itemprice: res.itemprice || 0,
          legalgasfee: res.legalgasfee || 0,
          gasfee: res.gasfee || 0,
          legalservicefee: res.legalservicefee || 0,
          servicefee: res.servicefee || 0,
          legalcreatorfee: res.legalcreatorfee || 0,
          creatorfee: res.creatorfee || 0,
          totalprice: res.totalprice || 0,
          legaltotalprice: res.legaltotalprice || 0
        }];
      });
    }
  }
};