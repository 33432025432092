var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "main"
  }, [_c("a-card", {
    staticClass: "orderInfo"
  }, [_c("div", {
    staticClass: "title"
  }, [_c("h3", [_c("span", {
    staticClass: "fs_16"
  }, [_vm._v("订单号:")]), _c("span", {
    staticClass: "ml-15 fs_16"
  }, [_vm._v(_vm._s(_vm.info.orderno || "--"))]), _c("span", {
    staticClass: "ml-15 red"
  }, [_vm._v(" " + _vm._s(_vm.info.orderstate == 0 ? "待付款" : _vm.info.orderstate == 1 ? "待转移" : _vm.info.orderstate == 2 ? "已完成" : _vm.info.orderstate == 3 ? "已关闭" : "---") + " ")])])]), _c("div", {
    staticClass: "content"
  }, [_c("div", [_c("span", {
    staticClass: "contKey"
  }, [_vm._v("卖家")]), _c("span", {
    staticClass: "contVal"
  }, [_vm._v(_vm._s(_vm.info.sellername))])]), _c("div", [_c("span", {
    staticClass: "contKey"
  }, [_vm._v("买家")]), _c("span", {
    staticClass: "contVal"
  }, [_vm._v(_vm._s(_vm.info.buyername))])]), _c("div"), _c("div", [_c("span", {
    staticClass: "contKey"
  }, [_vm._v("卖家邮箱")]), _c("span", {
    staticClass: "contVal"
  }, [_vm._v(_vm._s(_vm.info.sellermail))])]), _c("div", [_c("span", {
    staticClass: "contKey"
  }, [_vm._v("买家邮箱")]), _c("span", {
    staticClass: "contVal"
  }, [_vm._v(_vm._s(_vm.info.buyeremail))])]), _c("div")])]), _c("a-card", {
    staticClass: "payInfo"
  }, [_c("h3", [_vm._v("支付信息")]), _c("div", {
    staticClass: "detail"
  }, [_vm.info.orderstate && _vm.info.paytime ? _c("div", [_c("span", {
    staticClass: "contKey"
  }, [_vm._v("支付时间")]), _c("span", {
    staticClass: "contVal"
  }, [_vm._v(_vm._s(_vm.$moment(_vm.info.paytime * 1000).utc().format("YYYY-MM-DD HH:ii:ss")))])]) : _vm._e(), _c("div", [_c("span", {
    staticClass: "contKey"
  }, [_vm._v("买家钱包")]), _c("span", {
    staticClass: "contVal"
  }, [_c("span", [_vm._v(_vm._s(_vm.info.buyerwallettype == "1" ? "Meta Mask " : "---"))]), _c("span", [_vm._v(_vm._s(_vm.info.buyerwallet || "---") + " "), _c("a-icon", {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.info.buyerwallet,
      expression: "info.buyerwallet",
      arg: "copy"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.copySuccess,
      expression: "copySuccess",
      arg: "success"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:error",
      value: _vm.copyError,
      expression: "copyError",
      arg: "error"
    }],
    staticStyle: {
      color: "rgb(50, 112, 245)",
      cursor: "pointer"
    },
    attrs: {
      type: "copy"
    }
  })], 1)])]), _c("div", [_c("span", {
    staticClass: "contKey"
  }, [_vm._v("卖家钱包")]), _c("span", {
    staticClass: "contVal"
  }, [_c("span", [_vm._v(_vm._s(_vm.info.sellerwallettype == "1" ? "Meta Mask " : "---"))]), _c("span", [_vm._v(_vm._s(_vm.info.sellerwallet || "---") + " "), _c("a-icon", {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.info.sellerwallet,
      expression: "info.sellerwallet",
      arg: "copy"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.copySuccess,
      expression: "copySuccess",
      arg: "success"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:error",
      value: _vm.copyError,
      expression: "copyError",
      arg: "error"
    }],
    staticStyle: {
      color: "rgb(50, 112, 245)",
      cursor: "pointer"
    },
    attrs: {
      type: "copy"
    }
  })], 1)])]), _vm.info.orderstate && _vm.info.txhash ? _c("div", [_c("span", {
    staticClass: "contKey"
  }, [_vm._v("TXHASH")]), _c("span", {
    staticClass: "contVal"
  }, [_c("span", [_vm._v(_vm._s(_vm.info.txhash) + " "), _c("a-icon", {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.info.txhash,
      expression: "info.txhash",
      arg: "copy"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.copySuccess,
      expression: "copySuccess",
      arg: "success"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:error",
      value: _vm.copyError,
      expression: "copyError",
      arg: "error"
    }],
    staticStyle: {
      color: "rgb(50, 112, 245)",
      cursor: "pointer"
    },
    attrs: {
      type: "copy"
    }
  })], 1)])]) : _vm._e()]), _c("div", {
    staticStyle: {
      height: "16px"
    }
  }), _c("a-table", {
    attrs: {
      columns: _vm.priceColumns,
      "data-source": _vm.priceData,
      size: "middle",
      bordered: true,
      pagination: false
    },
    scopedSlots: _vm._u([_vm._l(_vm.priceColumns, function (_ref) {
      var key = _ref.key;
      return {
        key: key,
        fn: function fn(text, record) {
          return [key == "name" ? _c("span", {
            staticClass: "b600"
          }, [_vm._v(" 金额（$） ")]) : key == "price" ? _c("span", [_c("div", {
            staticClass: "b600"
          }, [_c("icon", {
            attrs: {
              type: "nft"
            }
          }), _vm._v(_vm._s(record.itemprice))], 1), _c("div", {
            staticClass: "b600"
          }, [_c("span", {
            staticClass: "dollar"
          }, [_vm._v("$")]), _vm._v(_vm._s(record.legalitemprice))])]) : key == "Gasfee" ? _c("span", [_c("div", {
            staticClass: "b600"
          }, [_c("icon", {
            attrs: {
              type: "nft"
            }
          }), _vm._v(_vm._s(record.gasfee))], 1), _c("div", {
            staticClass: "b600"
          }, [_c("span", {
            staticClass: "dollar"
          }, [_vm._v("$")]), _vm._v(_vm._s(record.legalgasfee))])]) : key == "servicefee" ? _c("span", [_c("div", {
            staticClass: "b600"
          }, [_c("icon", {
            attrs: {
              type: "nft"
            }
          }), _vm._v(_vm._s(record.servicefee))], 1), _c("div", {
            staticClass: "b600"
          }, [_c("span", {
            staticClass: "dollar"
          }, [_vm._v("$")]), _vm._v(_vm._s(record.legalservicefee))])]) : key == "creatorfee" ? _c("span", [_c("div", {
            staticClass: "b600"
          }, [_c("icon", {
            attrs: {
              type: "nft"
            }
          }), _vm._v(_vm._s(record.creatorfee))], 1), _c("div", {
            staticClass: "b600"
          }, [_c("span", {
            staticClass: "dollar"
          }, [_vm._v("$")]), _vm._v(_vm._s(record.legalcreatorfee))])]) : key == "total" ? _c("span", [_c("div", {
            staticClass: "b600"
          }, [_c("icon", {
            attrs: {
              type: "nft"
            }
          }), _vm._v(_vm._s(record.totalprice))], 1), _c("div", {
            staticClass: "b600"
          }, [_c("span", {
            staticClass: "dollar"
          }, [_vm._v("$")]), _vm._v(_vm._s(record.legaltotalprice))])]) : _c("span", [_c("span", {
            staticClass: "b600"
          }, [_vm._v(_vm._s(record[key] || "--"))])])];
        }
      };
    })], null, true)
  })], 1), _c("a-card", {
    staticClass: "proInfo"
  }, [_c("h3", {
    staticClass: "title"
  }, [_vm._v("商品信息")]), _c("div", {
    staticClass: "content prod_flex"
  }, [_c("div", {
    staticClass: "proImg img_left"
  }, [_c("div", {
    staticStyle: {
      width: "92px",
      height: "92px",
      position: "relative"
    }
  }, [_c("div", {
    staticClass: "product_img",
    on: {
      click: function click($event) {
        return _vm.preview(_vm.info.itemurl, _vm.info.itemtype);
      }
    }
  }, [_vm.info.itemtype == 2 ? _c("audio", {
    attrs: {
      controls: "controls",
      src: _vm.info.itemurl,
      controlslist: "nodownload"
    }
  }) : _vm.info.itemtype == 3 ? _c("video", {
    attrs: {
      controls: "controls",
      src: _vm.info.itemurl,
      controlslist: "nodownload"
    }
  }) : _c("img", {
    attrs: {
      src: _vm.info.itemurl
    }
  })])])]), _c("div", {
    staticClass: "pro_detail"
  }, [_c("div", [_c("span", {
    staticClass: "contKey1"
  }, [_vm._v("商品名称")]), _c("span", {
    staticClass: "contVal"
  }, [_vm._v(_vm._s(_vm.info.itemname || "--"))])]), _c("div", [_c("span", {
    staticClass: "contKey"
  }, [_vm._v("Contract Address")]), _c("span", {
    staticClass: "contVal"
  }, [_vm._v(_vm._s(!_vm.info.itemaddress ? "---" : _vm.info.itemaddress.length > 10 ? _vm.info.itemaddress.substr(0, 7) + "..." : _vm.info.itemaddress) + " "), _c("a-icon", {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.info.itemaddress,
      expression: "info.itemaddress",
      arg: "copy"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.copySuccess,
      expression: "copySuccess",
      arg: "success"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:error",
      value: _vm.copyError,
      expression: "copyError",
      arg: "error"
    }],
    staticStyle: {
      color: "rgb(50, 112, 245)",
      cursor: "pointer"
    },
    attrs: {
      type: "copy"
    }
  })], 1)]), _c("div", [_c("span", {
    staticClass: "contKey"
  }, [_vm._v("collection")]), _c("span", {
    staticClass: "contVal"
  }, [_vm._v(_vm._s(_vm.info.collectionname))])]), _c("div", [_c("span", {
    staticClass: "contKey1"
  }, [_vm._v("creator")]), _c("span", {
    staticClass: "contVal"
  }, [_vm._v(_vm._s(_vm.info.creatorname || "---"))])]), _c("div", [_c("span", {
    staticClass: "contKey"
  }, [_vm._v("类型")]), _c("span", {
    staticClass: "contVal"
  }, [_vm._v(" " + _vm._s(_vm.info.itemtype == 1 ? "图片" : _vm.info.itemtype == 2 ? "音频" : _vm.info.itemtype == 3 ? "视频" : "---") + " ")])]), _c("div", [_c("span", {
    staticClass: "contKey"
  }, [_vm._v("Token Standard")]), _c("span", {
    staticClass: "contVal"
  }, [_vm._v(_vm._s(_vm.info.itemstandard))])]), _c("div", [_c("span", {
    staticClass: "contKey1"
  }, [_vm._v("Token ID")]), _c("span", {
    staticClass: "contVal"
  }, [_vm._v(_vm._s(_vm.info.tokenid))])]), _c("div", [_c("span", {
    staticClass: "contKey"
  }, [_vm._v("Blockchain")]), _c("span", {
    staticClass: "contVal"
  }, [_vm._v(_vm._s(_vm.info.blockchain || "---"))])]), _c("div")])])]), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible,
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: _vm.previewHandleCancel
    }
  }), _c("PreviewMedia", {
    attrs: {
      isShow: _vm.isShowMedia,
      previewSource: _vm.mediaSource,
      mediaType: _vm.mediaType,
      modalWidth: _vm.mediaType == "audio" ? "380px" : "600px"
    },
    on: {
      closePreview: _vm.previewHandleCancel
    }
  }), _vm.info.loglist && _vm.info.loglist.length > 0 ? _c("a-card", {
    staticClass: "action"
  }, [_c("h3", [_vm._v("订单操作日志")]), _c("a-table", {
    staticClass: "log-table",
    attrs: {
      pagination: false,
      dataSource: _vm.info.loglist,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return i;
      },
      loading: _vm.loading,
      bordered: ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (_ref2) {
      var key = _ref2.key;
      return {
        key: key,
        fn: function fn(text, record) {
          return [_c("span", {
            staticStyle: {
              "font-size": "14px",
              color: "#646464"
            }
          }, [key == "logtime" ? _c("span", [_vm._v(" " + _vm._s(text ? _vm.$moment(text * 1000).utc().format("YYYY-MM-DD HH:mm:ss") : "---") + " ")]) : _c("span", [_c("span", [_vm._v(_vm._s(record[key] || "--"))])])])];
        }
      };
    })], null, true)
  })], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };